<template>
  <div class="record">
    <div class="headlineBox mt-30">
      <span class="head_box">基本信息</span>
    </div>
    <div class="essential_info">
      <img v-real-img="myInfo.Avatar" src="@/assets/images/student/default.png" class="my_img mr-32" alt="" />
      <div class="my_infoBox">
        <div class="name mb-14">{{ myInfo.Name }}</div>
        <div class="my_info">
          <div class="left mr-30">
            <div class="my_type mb-10">
              性别：<span class="txt">{{ myInfo.SexStr }}</span>
            </div>
            <!-- <div class="my_type mb-10">
              年级：<span class="txt">{{ myInfo.Grade }}</span>
            </div> -->
            <div class="my_type mb-10">
              班级：<span class="txt">{{ myInfo.ClassName }}</span>
            </div>
          </div>
          <div class="right">
            <div class="my_type mb-10">
              学号：<span class="txt">{{ myInfo.Code }}</span>
            </div>
            <div class="my_type mb-10">
              专业：<span class="txt">{{ myInfo.MajorName }}</span>
            </div>
            <!-- <div class="my_type mb-10">
              民族：<span class="txt">{{ myInfo.Ethnic }}</span>
            </div> -->
          </div>
          <!-- <div class="right">
            <div class="my_type mb-10">
              手机：<span class="txt">{{ myInfo.Phone }}</span>
            </div>
            <div class="my_type mb-10">
              出生日期：<span class="txt">{{ myInfo.Birthday }}</span>
            </div>
            <div class="my_type mb-10">
              学校：<span class="txt">{{ myInfo.SchoolName }}</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="headlineBox mt-30 mb-30">
      <span class="head_box">考勤统计</span>
    </div>
    <div class="table_box">
      <el-table border v-loading="loading" :data="signRecordList" stripe style="width: 100%">
        <el-table-column width="" label="打卡规则" prop="RuleName" />
        <el-table-column width="100" label="日期" prop="SignDate" />
        <el-table-column width="80" label="上班时间" prop="SignInRuleTime" />
        <el-table-column width="150" label="上班打卡时间" prop="SignInTime" />
        <el-table-column width="" label="上班打卡状态" prop="SignInStatus" />
        <el-table-column width="80" label="下班时间" prop="SignOutRuleTime" />
        <el-table-column width="150" label="下班打卡时间" prop="SignOutTime" />
        <el-table-column width="" label="下班打卡状态" prop="SignOutStatus" />
        <!-- <el-table-column width="" label="状态" prop="Status" /> -->
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.Grade + " " + scope.row.ClassName }}</span>
        </template>
      </el-table-column>-->
        <!-- <el-table-column width="" label="" prop="Status">
          <template slot-scope="scope">
            <span>{{ scope.row.AbilityCount || "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="mini"
              icon="el-icon-s-promotion"
              @click="showAbility(scope.row)"
              >能力</el-button
            >
            <el-button
              type="text"
              size="mini"
              icon="el-icon-s-cooperation"
              @click="showRecord(scope.row)"
              >档案</el-button
            >
          </template>
        </el-table-column>
        -->
      </el-table>
      <div class="headlineBox mt-30 mb-30">
        <span class="head_box">假勤统计</span>
      </div>
      <el-table border v-loading="loading" :data="leaveList" stripe style="width: 100%">
        <el-table-column width="" label="名字" prop="StudentName" />
        <el-table-column width="" label="申请原因" prop="ApplyReason" />
        <el-table-column width="" label="发起时间" prop="ApplyStartDate" />
        <el-table-column width="" label="结束时间" prop="ApplyEndDate" />
        <el-table-column width="" label="状态" prop="Status_V" />
      </el-table>
      <div class="headlineBox mt-30 mb-30">
        <span class="head_box">在线学习统计</span>
      </div>
      <el-table border v-loading="loading" :data="courseList" stripe style="width: 100%">
        <el-table-column width="" label="课程名称" prop="Name" />
        <el-table-column width="" label="课程简介" prop="Introduction" />
        <el-table-column width="" label="学习数量" prop="LearningCount" />
        <el-table-column width="" label="课程数量" prop="LessonCount" />
        <el-table-column width="" label="资料数量" prop="MaterialCount" />
        <el-table-column width="" label="学习时长" prop="LearningCount" />
      </el-table>
      <div class="headlineBox mt-30 mb-30">
        <span class="head_box">日报统计</span>
      </div>
      <el-table border v-loading="loading" :data="dailyList" stripe style="width: 100%">
        <el-table-column width="" label="项目班" prop="LayoutName" />
        <el-table-column width="" label="学生名字" prop="StudentName" />
        <el-table-column width="300" label="日报内容" prop="Content" />
        <el-table-column width="300" label="问题&心得" prop="Question" />
        <el-table-column width="200" label="日期" prop="Title" />
        <el-table-column width="" label="日报类型" prop="Type_V" />
      </el-table>
      <div class="headlineBox mt-30 mb-30">
        <span class="head_box">项目产出统计</span>
      </div>
      <el-table border v-loading="loading" :data="saleList" stripe style="width: 100%">
        <el-table-column width="" label="备注" prop="Content" />
        <el-table-column width="" label="日期" prop="SaleDate" />
        <el-table-column width="" label="项目订单数量" prop="OrderCount" />
        <el-table-column width="" label="项目利润" prop="Profit" />
        <el-table-column width="" label="项目销售额" prop="Turnover" />
      </el-table>
    </div>
  </div>
</template>

<script>
// import Detail from "./components/Detail";
import {
  getSignRecordList,
  getLeaveList,
  getCourseList,
  getSaleList,
  getDailyList,
  getInfo
} from "@/api/student";
export default {
  components: {},
  data() {
    return {
      loading: false,
      id: null,
      myInfo: {},
      signRecordList: [], // 签到考勤
      leaveList: [], //假勤
      courseList: [], //课程
      saleList: [], //销售
      dailyList: [] //
    };
  },
  statusFilter(status) {
    const statusMap = {
      "-5": "error",
      "-4": "error",
      "-3": "error",
      "-2": "warning",
      "-1": "warning",
      0: "erroe",
      1: "success",
      2: "success",
      3: "success",
      4: "success"
    };
    if (statusMap[status]) {
      return statusMap[status];
    } else {
      return "";
    }
  },
  created() {
    this.id = this.$route.query.id;
    // this.getSignRecordList()
    this.getInfo();
    this.getSignRecordList();
    this.getLeaveList();
    this.getCourseList();
    this.getSaleList();
    this.getDailyList();
  },
  methods: {
    async getInfo() {
      this.loading = true;
      try {
        const { Result } = await getInfo(this.id);
        this.myInfo = Result;
        // console.log(Result);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getSignRecordList() {
      this.loading = true;
      try {
        const { Result } = await getSignRecordList(this.id);
        Result.Data.forEach(el => {
          el.SignDate = el.SignDate.slice(0, 10);
          // el.SignDate = el.SignDate
        });
        this.signRecordList = Result.Data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getLeaveList() {
      this.loading = true;
      try {
        const { Result } = await getLeaveList(this.id);
        this.leaveList = Result.Data;
        // console.log(this.leaveList);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getCourseList() {
      this.loading = true;
      try {
        const { Result } = await getCourseList(this.id);
        this.courseList = Result.Data;
        // console.log("课程", this.courseList);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getSaleList() {
      this.loading = true;
      try {
        const { Result } = await getSaleList(this.id);
        this.saleList = Result.Data;
        // console.log("销售", this.saleList);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getDailyList() {
      this.loading = true;
      try {
        const { Result } = await getDailyList(this.id);
        this.dailyList = Result.Data;
        // console.log("每日清单", this.dailyList);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.record {
  padding: 24px;
  box-sizing: border-box;
  // width: 100%;
  // min-height: 73vh;
  height: 72vh;
  overflow: auto;
  .table_box {
    width: 100%;
    box-sizing: border-box;
    height: 999px;
  }
  .headlineBox {
    width: 100%;
    height: 32px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
    border-bottom: 1px solid #ededed;
    .head_box {
      box-sizing: border-box;
      display: inline-block;
      height: 100%;
      border-bottom: 2px solid #3d5afe;
    }
  }
  .essential_info {
    width: 100%;
    height: 220px;
    display: flex;
    padding: 30px;
    box-sizing: border-box;
    .my_img {
      width: 130px;
      height: 160px;
      object-fit: cover;
    }
    .my_infoBox {
      .name {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 37px;
      }
      .my_info {
        display: flex;
        .left {
          width: 380px;
          min-height: 100px;
          .my_type {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
            .txt {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
            }
          }
        }
        .right {
          width: 380px;
          min-height: 100px;
          .my_type {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
            .txt {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}
</style>
